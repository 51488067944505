$(document).ready(function(){
    /* cookie popup */
    $('.eu-cookies button').click(function() {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 5);
        document.cookie = 'eu-cookies=1; path=/; expires=' + date.toGMTString();
        $('.eu-cookies').fadeOut();
    });

    /* increase decrease item quantity */
    $('.quantity .minus').click(function(){
        var qty = $(this).parent().find('.qty');
        qty.val(qty.val() - 1);
        if(qty.val() <= 1){
            qty.val(1);
        }
    });

    $('.quantity .plus').click(function(){
        var qty = $(this).parent().find('.qty');
        qty_val = parseFloat(qty.val());
        qty.val(qty_val + 1);
    });

    /* add item to cart */
    $('.add-to-cart-ajax').on('click', function(e){
        e.preventDefault();
        var form = $(this).closest('form'),
            url = form.attr('action'),
            token = form.find('input[name=_token]').val(),
            product = form.find('input[name=product]').val(),
            image = $(this).closest('.product-inner').find('.feature-img img').attr('src'),
            title = $(this).closest('.product-inner').find('.product-desc h3 a').attr('title');


        $.ajax({
            url: url,
            type: "POST",
            data: {
                "_token": token,
                'product': product
            }
        }).then(function (response) {
            var preparedTitle = title.length > 25 ? title.substr(0, 23) + '...' : title,
                popup = $(getPopupTpl(image, preparedTitle));

            $("#cart-price").html(response.data.subtotal);

            if ( $('.cart-popup').length ) {
                popup.css('top', ($('.cart-popup').length * 75 + parseInt($('.cart-popup').css('top'))) + 'px');
            }

            $('body').append(popup);

            popup.hide().fadeIn();

            setTimeout(function () {
                popup.fadeOut(function () {
                    $(this).remove();
                });
            }, 4000);
        }, function(response){
            console.log(response);
        });
    });

    function getPopupTpl(img, title){
        return '<div class="cart-popup flex-row">' +
                    '<div class="image">' +
                        '<img src="' + img + '" >' +
                    '</div>' +
                    '<div class="text">' +
                        '<div class="title">' + title + '</div>' +
                        '<span>bol pridaný do košíka.</span>' +
                    '</div>' +
                '</div>';
    }
});

var cookies = {
    create: function (name, value, days) {
        var expires;

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    },
    get: function (name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    },
    delete: function (name) {
        cookies.create(name, "", -1);
    }
};





